import React from 'react';
import { useRecoilState } from 'recoil';
import { CSSProperties } from 'styled-components';
import { supportsWebp } from '../atoms/webp';
import ProgressiveImage from 'react-progressive-image'

const OptimisedImage: React.FC<{
  name: string;
  type: 'png' | 'jpg' | 'jpeg';
  style?: CSSProperties;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
  inStorage?: boolean;
}> = ({
  name,
  type,
  style,
  alt,
  className,
  width,
  height,
  inStorage
}) => {
  const [supportsWebpValue, _] = useRecoilState(supportsWebp);

  const getPrefix = () => {
    if (inStorage) {
      return process.env.REACT_APP_STORAGE_URL
    }

    return '/'
  }

  return (
    supportsWebpValue === null ? <></> :
    supportsWebpValue ? 
    <ProgressiveImage src={getPrefix() + name + '.webp'} placeholder={getPrefix() + name + '_small.webp'}>
      {(src: string | undefined) => 
        <img 
          src={src} 
          style={style} 
          alt={alt} 
          className={className} 
          width={width}
          height={height}
        />
      }
    </ProgressiveImage> : 
    <ProgressiveImage src={getPrefix() + name + '.' + type} placeholder={getPrefix() + name + '_small.' + type}>
      {(src: string | undefined) => 
        <img 
          src={src} 
          style={style} 
          alt={alt} 
          className={className} 
          width={width}
          height={height}
        />
      }
    </ProgressiveImage> 
  )
};

export default OptimisedImage;
